<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>Ajouter un modèle</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
          </div>
          <vs-divider class="mb-0"></vs-divider>
          <div class="w-full mt-5 px-6">
            <vs-input name="libelle" class="w-full" label="Libellé modèle" v-model="libelle"></vs-input>
          </div>
          <div class="w-full mt-5 px-6">
            <vs-select label="Officines" multiple autocomplete class="w-full" v-model="Officines">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="w-full flex justify-end mt-5 px-6">
            <vs-button class="mr-2" @click="createModelEntete">Sauvegarder</vs-button>
            <vs-button color="danger" type="border" @click="isSidebarActiveLocal = false">Annuler</vs-button>
          </div>
        </vs-sidebar>
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocalUpdate">
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>Modifier modèle: {{ libelle }}</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocalUpdate = false" class="cursor-pointer"></feather-icon>
          </div>
          <vs-divider class="mb-0"></vs-divider>
          <div class="w-full mt-5 px-6">
            <vs-input name="libelle" class="w-full" label="Libellé modèle" v-model="libelle"></vs-input>
          </div>
          <div class="w-full mt-5 px-6">
            <vs-select label="Officines" multiple autocomplete class="w-full" v-model="Officines">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="w-full flex justify-end mt-5 px-6">
            <vs-button class="mr-2" @click="updateCreateModelEntete">Sauvegarder</vs-button>
            <vs-button color="danger" type="border" @click="isSidebarActiveLocalUpdate = false">Annuler</vs-button>
          </div>
        </vs-sidebar>
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocalLogo">
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>Logo & pied de page: {{ libelle }}</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocalLogo = false;libelle = null;idModele = null;" class="cursor-pointer"></feather-icon>
          </div>
          <vs-divider class="mb-0"></vs-divider>
          <div class="w-full flex mt-2 px-6">
            <logo-pied-page-image-upload limit="1" text="LOGO" :url="buildSendLogoUrl"></logo-pied-page-image-upload>
          </div>
          <div class="w-full mt-2 px-6">
            <logo-pied-page-image-upload limit="1" text="PIED DE PAGE" :url="buildSendPiedpageUrl"></logo-pied-page-image-upload>
          </div>
          <br><br><br><br><br>
        </vs-sidebar>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table noDataText="Aucun modèle sauvegardé" stripe pagination max-items="20" search :data="modeles">
              <template slot="header">
                <vs-button @click="isSidebarActiveLocal=true" class="mb-4 md:mb-0">Ajouter modèle</vs-button>
              </template>
              <template slot="thead">
                <vs-th>
                  N°
                </vs-th>
                <vs-th sort-key="libelle">
                  Libelle
                </vs-th>
                <vs-th>
                  logo
                </vs-th>
                <vs-th class="text-left">
                  Actions
                </vs-th>

              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    {{ indextr + 1 }}
                  </vs-td>
                  <vs-td>
                    {{ tr.libelle }}
                  </vs-td>
                  <vs-td>
                    <img crossorigin="anonymous" :src="tr.urlEntete" alt="Logo" height="100px">
                  </vs-td>
                  <vs-td class="flex justify-end">

                    <vx-tooltip
                      position="left"
                      color="black"
                      text="Logo & pied de page"
                    >
                      <feather-icon icon="ImageIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="addLogoPiedPage(tr)" />
                    </vx-tooltip>
                    <vx-tooltip
                      position="left"
                      color="black"
                      text="Modifier"
                    >
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showFormUpdate(tr)" />
                    </vx-tooltip>
                    <vx-tooltip
                      position="left"
                      color="black"
                      text="Supprimer"
                    >
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id)" />
                    </vx-tooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
    <!-- <div  class="vx-col md:w-3/4">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full m-h-a4">
            <div class="vx-row leading-loose">
              <div class="vx-col w-1/2 mt-base">
                <img :src="getLogoUrl" alt="Logo" height="100px">
              </div>
            </div>
            <br><br><br><br><br><br><br><br><br><br>
            <br><br><br><br><br><br><br><br><br><br>
            <div class="invoice__footer text-center p-1 mt-5">
              <div class="vx-row">
                <div class="vx-col w-full mt-base">
                  <img :src="getPiedpageUrl" alt="Pied de page" width="100%" height="60px">
                </div>
              </div>
            </div>


          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <div class="vx-orw">
          <div class="vx-col w-full">
            <template v-if="getLogoUrl">
              <vs-button @click="removeLogo()" color="danger" class="w-full" icon-pack="feather" icon="icon-trash" >SUPPRIMER LOGO</vs-button>
            </template>
            <template v-else>
              <logo-pied-page-image-upload limit="1" text="LOGO" :url="buildSendLogoUrl"></logo-pied-page-image-upload>
            </template>
          </div>
          <div class="vx-col w-full mt-4">
            <template v-if="getPiedpageUrl">
              <vs-button @click="removePiedPage()" color="danger" class="w-full" icon-pack="feather" icon="icon-trash" >SUPPRIMER PIED DE PAGE</vs-button>
            </template>
            <template v-else>
              <logo-pied-page-image-upload limit="1" text="PIED DE PAGE" :url="buildSendPiedpageUrl"></logo-pied-page-image-upload>
            </template>
          </div>
        </div>
      </vx-card>
    </div> -->
  </div>
</template>

<script>
/* lazy loading Vue components */
import logoPiedPageImageUpload from './component/logoPiedPageImageUpload.vue'

export default {
  name: 'entete_piedoage',
  components: {
    logoPiedPageImageUpload
  },
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,
      IdOfficine: null,
      libelle: null,
      Officines: [],
      idModele: null,

      isSidebarActiveLocal: false,
      isSidebarActiveLocalUpdate: false,
      isSidebarActiveLocalLogo: false

    }
  },
  computed: {
    buildSendLogoUrl () {
      return `/api/entete-piedpage/add-logo/${this.idModele}`
    },
    buildSendPiedpageUrl () {
      return `/api/entete-piedpage/add-piedpage/${this.idModele}`
    },
    getLogoUrl () {
      return this.$store.getters['entete_piedpage/getLogoUrl']
    },
    getPiedpageUrl () {
      return this.$store.getters['entete_piedpage/getPiedpageUrl']
    },
    officines () {
      return this.$store.state.officine.officines
    },
    modeles () {
      return this.$store.state.entete_piedpage.modeles
    },
    validForm () {
      return (this.libelle !== null || this.libelle !== '')
    }
  },
  methods: {
    showFormUpdate (modele) {
      this.libelle = modele.libelle
      this.Officines = modele.Officines
      this.idModele = modele._id

      this.isSidebarActiveLocalUpdate = true
    },
    addLogoPiedPage (modele) {
      this.libelle = modele.libelle
      this.idModele = modele._id

      this.isSidebarActiveLocalLogo = true
    },
    activeUserInfo () {
      this.IdOfficine = this.activeUserInfos.IdOfficine
    },
    confirmDeleteRecord (_id) {
      this.idModele = _id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer !',
        text: 'Etes-vous sûr de vouloir supprimer définitivement cet modèle ? Cette action ne peut pas être annulée',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      console.log('id: ', this.idModele)
      this.$store.dispatch('entete_piedpage/removeModelEntete', this.idModele)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => {
          console.log(err)
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'primary',
        title: 'Suppression',
        text: 'Suppression réussie. L\'élément a été retiré avec succès de votre compte.'
      })
    },
    createModelEntete () {
      this.$vs.loading({
        type: 'sound'
      })
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.activeUserInfos._id,
        libelle: this.libelle,
        Officines: this.Officines
      }
      this.$store.dispatch('entete_piedpage/createModelEntete', payload)
        .then(() => {
          this.resetData()
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'primary',
            title: 'Success',
            text: 'Modèle ajouter avec success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    updateCreateModelEntete () {
      this.$vs.loading({
        type: 'sound'
      })
      const payload = {
        id: this.idModele,
        IdOfficine: this.IdOfficine,
        IdUser: this.activeUserInfos._id,
        libelle: this.libelle,
        Officines: this.Officines
      }
      this.$store.dispatch('entete_piedpage/updateCreateModelEntete', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'primary',
            title: 'Success',
            text: 'Modèle modifier avec success'
          })
          this.resetData()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    resetData () {
      this.idModele = null
      this.libelle = null
      this.Officines = []
    },
    removeLogo () {
      this.$vs.loading({
        type: 'sound'
      })
      const id = this.$store.getters['entete_piedpage/getLogoId']
      this.$store.dispatch('entete_piedpage/removeLogo', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    removePiedPage () {
      this.$vs.loading({
        type: 'sound'
      })
      const id = this.$store.getters['entete_piedpage/getPiedpageId']
      this.$store.dispatch('entete_piedpage/removePiedPage', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getAllModelEntetePiedPage () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('entete_piedpage/getAllModelEntetePiedPage')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getLogo () {
      if (this.$store.state.entete_piedpage.logo) {
        this.$store.dispatch('entete_piedpage/getLogo')
          .then(() => {

          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getPiedPage () {
      if (this.$store.state.entete_piedpage.piedpage) {
        this.$store.dispatch('entete_piedpage/getPiedPage')
          .then(() => {

          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then((resp) => {
          console.log(resp)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getAllModelEntetePiedPage()
    this.getLogo()
    this.getPiedPage()
    this.getOfficines()
  }
}
</script>

<style lang="scss" scoped>
.not-data-table{
  display: none;
}

/* .tableCustom {
  border:   1px solid black;
} */
.trCustom, .thCustom {
  border: 1px solid black;
  padding: 4px;
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 30vw;
    max-width: 30vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
